/* eslint-disable react/no-unescaped-entities */
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import TitleSection from "../../components/TitleSection";
import Heading from "../../components/Heading";
import "animate.css";
import Text from "../../components/Text";
import CartText from "../../components/Cards/CartText/Index";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";
import Carousel from "../../components/Carousel";

const Install = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TitleSection
        title="Instala la aplicación de Alexa"
        color="primary"
        number="6"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="px-4 md:px-0 mt-8">
          <Heading
            size="xl"
            variant="bold"
            color="primary"
            className="text-center"
          >
            ¡Descarga gratis la app de alexa!
          </Heading>
          <div className="w-full flex justify-center gap-4 mt-6">
            <a className="cursor-pointer">
              <StaticImage
                alt="google"
                className="w-auto"
                src="../../images/google.png"
              />
            </a>
            <a className="cursor-pointer">
              <StaticImage
                alt="google"
                className="w-auto"
                src="../../images/apple.png"
              />
            </a>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 px-4 sm:px-10 items-center gap-8 mx-auto mt-8 max-w-[1200px]">
          {/* INGRESA */}
          <Carousel
            data={[
              <div className="col-span-3">
                <CartText
                  // animation="animate__animated animate__fadeInLeft animation-duration"
                  animation=""
                  number="1"
                  direcction="lg:flex-row"
                  bg="bg-secondary"
                  text={
                    <Text
                      size="2xl"
                      color="secondary"
                      variant="bold"
                      className="text-center"
                    >
                      Selecciona la opción de
                      <br />
                      <span className="text-primary">
                        CREAR UNA NUEVA CUENTA DE AMAZON
                      </span>
                    </Text>
                  }
                  img="login-alexa.png"
                />
              </div>,
              <div className="col-span-3">
                <CartText
                  // animation="animate__animated animate__fadeInRight animation-duration"
                  animation=""
                  number="2"
                  direcction="lg:flex-row lg:flex-row-reverse"
                  bg="bg-primary"
                  img="create-alexa.png"
                  text={
                    <Text
                      size="2xl"
                      color="secondary"
                      variant="bold"
                      className="text-center"
                    >
                      llena todos los campos del formulario y selecciona la
                      opción de{" "}
                      <span className="text-primary">
                        CREA TU CUENTA DE AMAZON.
                      </span>{" "}
                      <br />
                      Nota: Recuerda que el correo que utilices para crear tu
                      cuenta de amazon debe ser la misma que utilizas en la
                      página HyPUCP
                    </Text>
                  }
                />
              </div>,
              <div className="col-span-3">
                <CartText
                  animation=""
                  // animation="animate__animated animate__fadeInLeft animation-duration"
                  number="3"
                  bg="bg-secondary"
                  direcction="lg:flex-row"
                  text={
                    <Text
                      size="2xl"
                      color="secondary"
                      variant="bold"
                      className="text-center"
                    >
                      Ingresa con tu usuario y contraseña
                    </Text>
                  }
                  img="alexa-login.png"
                />
              </div>,
              <div className="col-span-3">
                <div className="flex items-center gap-4 lg:gap-6 ">
                  <div className="min-w-[48px] min-h-[48px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] bg-primary rounded-full flex justify-center items-center">
                    <p className="text-white font-bold text-2xl sm:text-3xl md:text-4xl">
                      4
                    </p>
                  </div>

                  <p className="text-secondary font-bold text-lg sm:text-xl  md:text-2xl text-center ">
                    Ingresa a la aplicación y sigues los pasos, puedes modificar
                    las configuraciones según tu interés
                  </p>
                </div>
              </div>,
              <div className="mt-2 justify-center">
                <StaticImage
                  alt="login"
                  className="w-auto shadow-lg"
                  src="../../images/step-uno.png"
                />
              </div>,
              <div className="mt-2 justify-center">
                <StaticImage
                  alt="login"
                  className="w-auto shadow-lg"
                  src="../../images/step-dos.png"
                />
              </div>,
              <div className="mt-2 justify-center">
                <StaticImage
                  alt="login"
                  className="w-auto shadow-lg"
                  src="../../images/step-tres.png"
                />
              </div>,
            ]}
          />
          {/* CREATE CUENTA */}
          {/* INICIA SESION */}
          {/* STEPS */}
          {/* <div
            className={`flex flex-col  gap-4 items-center w-full ${
              inView &&
              "animate__animated animate__fadeInRight animation-duration"
            }`}
            ref={ref}
          >
            <div className="flex  items-center gap-4 lg:gap-6 ">
              <div className="min-w-[48px] min-h-[48px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] bg-primary rounded-full flex justify-center items-center">
                <p className="text-white font-bold text-2xl sm:text-3xl md:text-4xl">
                  4
                </p>
              </div>

              <p className="text-secondary font-bold text-lg sm:text-xl  md:text-2xl text-center ">
                Ingresa a la aplicación y sigues los pasos, puedes modificar las
                configuraciones según tu interés
              </p>
            </div>

            <div className="gap-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
              <div className="mt-2 w-full flex justify-center">
                <StaticImage
                  alt="login"
                  className="w-auto shadow-lg"
                  src="../../images/step-uno.png"
                />
              </div>
              <div className="mt-2 w-full flex justify-center">
                <StaticImage
                  alt="login"
                  className="w-auto shadow-lg"
                  src="../../images/step-dos.png"
                />
              </div>
              <div className="mt-2 w-full flex justify-center">
                <StaticImage
                  alt="login"
                  className="w-auto shadow-lg"
                  src="../../images/step-tres.png"
                />
              </div>
            </div>
          </div> */}
        </div>
      </ContentSectionLayout>
    </>
  );
};

export default Install;
