import { StaticImage } from "gatsby-plugin-image";

import { useInView } from "react-intersection-observer";

import "animate.css";

import TitleSection from "../../components/TitleSection";
import ItemsStepNext from "../../components/ItemsStepNext";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";

const StepNext = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TitleSection
        title="Haz el siguiente flujo como ejemplo. Luego realiza las búsquedas sobre tus documentos que subiste"
        color="primary"
        number="11"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div
          className={`flex flex-col mdx:flex-row mdx:justify-center items-center relative w-full h-auto sm:w-[500px] mx-auto mdx:mx-0 ${
            inView &&
            "animate__animated animate__fadeInRight animation-duration"
          }`}
          ref={ref}
        >
          <div className="w-[330px] h-[330px] sm:w-[430px] sm:h-[430px] mdx:w-[500px] mdx:h-[500px]  rounded-full border-r-4 border-y-4 border-l-4 border-y-gray-100 border-l-gray-100  border-r-secondary flex justify-center items-center relative">
            <div className="w-[80%] h-[80%] bg-primary rounded-full">
              <StaticImage
                alt="step-next"
                className="w-full h-full rounded-full"
                src="../../images/img-step-next.png"
              />
            </div>
          </div>
          <ItemsStepNext
            number="1"
            text="Menciona lo siguiente: “Alexa abre mi aprendizaje”."
            className="mdx:absolute top-[15px] right-[-417px]"
          />
          <ItemsStepNext
            number="2"
            text="Alexa busca que es “El tema que quieras”."
            className="mdx:absolute top-[118px] right-[-402px]"
          />
          <ItemsStepNext
            number="3"
            text="Alexa realiza un resumen."
            className="mdx:absolute top-[246px] right-[-281px]"
          />
          <ItemsStepNext
            number="4"
            text="Alexa que tema es."
            className="mdx:absolute top-[364px] right-[-180px]"
          />
        </div>
      </ContentSectionLayout>
    </>
  );
};

export default StepNext;
