import { ReactNode } from 'react'

import './card-flip.css'

interface Props {
  textFront: string
  icon: ReactNode
  animation: string
}

const CartFlip = ({ textFront, icon, animation }: Props) => {
  return (
    <>
      <div className={`flip-container ${animation}`}>
        <div className="card">
          <div className="front flex items-center gap-4 flex-col">
            {icon}
            <p className="font-bold text-[18px] text-center text-white relative z-20">
              {textFront}
            </p>
          </div>
          <div className="back">
            <p className="font-bold">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis,
              tenetur?
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartFlip
