import { useInView } from "react-intersection-observer";

import "animate.css";
import TitleSection from "../../components/TitleSection";
import Text from "../../components/Text";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../components/Button";
import { BsCursor } from "react-icons/bs";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";

const BeforeStart = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`w-full h-full ${
        inView && "animate__animated animate__fadeInRight animation-duration"
      }`}
      ref={ref}
    >
      <TitleSection
        title="Antes de iniciar el Beta TEST requerimos tu correo electrónico"
        color="primary"
        number="3"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="flex flex-col gap-4 items-center py-6 px-4 md:px-0">
          <div className="mt-2 w-full flex justify-center">
            <StaticImage
              alt="login"
              className="max-w-[300px] md:max-w-none w-auto"
              src="../../images/code-dos.png"
            />
          </div>
          <Text size="xl" color="secondary" variant="bold">
            Por favor, accede al código QR con tu móvil o presiona el siguiente
            botón
          </Text>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.gle/iToc42REBGyW5rNJ7"
            className="mx-auto"
          >
            <Button
              rightIcon={<BsCursor className="w-6 h-6 lg:w-10 lg:h-10" />}
            >
              Ir al formulario
            </Button>
          </a>
        </div>
      </ContentSectionLayout>
    </div>
  );
};

export default BeforeStart;
