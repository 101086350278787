import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  number: string
  text: string
}

const ItemsStepNext = ({ number, text, ...props }: Props) => {
  return (
    <>
      <div {...props}>
        <div className="flex flex-col mdx:flex-row items-center px-2 mdx:px-0">
          <div className=" border-[4px] bg-gray-100 border-gray-100 min-w-[80px]  h-[80px] rounded-full flex items-center relative z-20">
            <div className="border-[12px] border-primary rounded-full w-full h-full flex  justify-center items-center">
              <p className="text-primary text-xl font-bold">{number}</p>
            </div>
          </div>
          <p className="p-4 bg-secondary w-auto text-white font-semibold rounded-lg lg:truncate translate-y-[-8px] mdx:translate-y-[0px] mdx:translate-x-[-8px] relative z-10">
            {text}
          </p>
        </div>
      </div>
    </>
  )
}

export default ItemsStepNext
