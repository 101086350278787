import { ReactNode } from "react";
import { classNames } from "../../utils/classNames";

const ContentSectionLayout = ({
  children,
  isOpen,
}: {
  children: ReactNode | ReactNode[];
  isOpen: boolean;
}) => {
  return (
    <div
      className={classNames([
        "transition-all duration-300",
        !isOpen ? "h-[0px] overflow-hidden" : "h-auto",
      ])}
    >
      {children}
    </div>
  );
};

export default ContentSectionLayout;
