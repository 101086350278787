import type { CSSProperties, ReactElement } from 'react'
import Spinner from '../Spinner'

import * as styles from './index.module.css'

interface Props {
  loading?: boolean
  className?: string
  style?: CSSProperties
  rightIcon?: ReactElement
  children: string | ReactElement | ReactElement[]
}

const Button = ({ children, className, style, loading, rightIcon }: Props) => {
  return (
    <button
      style={style}
      disabled={loading}
      className={[styles.button, className].join(' ')}
    >
      <div>{loading ? <Spinner /> : rightIcon}</div>
      <span>{children}</span>
    </button>
  )
}

export default Button
