import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

import "animate.css";

import TitleSection from "../../components/TitleSection";
import Text from "../../components/Text";
import { useState } from "react";
import ContentSectionLayout from "../../components/ContentSectionLayout";

const WhatIs = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full h-full" ref={ref}>
      <TitleSection
        title="¿Qué es?"
        color="primary"
        number="1"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="flex flex-col-reverse sm:flex-row gap-6 items-center px-4 py-6">
          <Text
            className={`text-center ${
              inView && "animate__animated animate__fadeInUp animation-duration"
            }`}
            size="2xl"
            color="secondary"
            variant="semibold"
          >
            HyPUCP es una solución tecnológica que permite a los estudiantes
            encontrar la información de sus cursos de manera rápida a través del
            uso de inteligencia artificial desde su smartphone.
          </Text>
          <StaticImage
            alt="step-verify-uno"
            className={`shadow-lg min-w-[300px] max-w-[300px] h-[300px] rounded-full ${
              inView && "animate__animated animate__fadeInUp animation-duration"
            }`}
            src="../../images/equipo.jpg"
          />
        </div>
      </ContentSectionLayout>
    </div>
  );
};

export default WhatIs;
