import { useInView } from "react-intersection-observer";

import "animate.css";

import WhatIs from "../../sections/como-funciona/WhatIs";
import Characteristic from "../../sections/como-funciona/Characteristic";
import Create from "../../sections/como-funciona/Create";
import Document from "../../sections/como-funciona/Document";
import Install from "../../sections/como-funciona/Install";
import InstallSkill from "../../sections/como-funciona/InstallSkill";
import SettingLenguaje from "../../sections/como-funciona/SettingLenguaje";
import Verify from "../../sections/como-funciona/Verify";
import Use from "../../sections/como-funciona/Use";
import StepNext from "../../sections/como-funciona/StepNext";
import FormCode from "../../sections/como-funciona/FormCode";
import BeforeStart from "../../sections/como-funciona/BeforeStart";

const ComoFunciona = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <>
      <section className="bg-gray-100 ">
        <h3 className="text-3xl lg:text-5xl xl:text-6xl 3xl:text-7xl text-secondary text-center my-6">
          ¿Como <span className="font-black">funciona? </span>
        </h3>
        {/* Wolcome */}
        <div
          className="pb-5 sm:pb-8 md:pb-10 flex flex-col gap-2 sm:gap-4 md:gap-6 max-w-[500px] mx-auto px-5 md:px-0"
          ref={ref}
        >
          {/* TITLE */}
          <h1
            className={` text-3xl md:text-4xl lg:text-6xl  text-secondary text-center font-bold ${
              inView &&
              "animate__animated animate__fadeInLeft animation-duration"
            }`}
          >
            Beta TEST <br />
            <span className="text-[#0E96B8]">de HyPUCP</span>
          </h1>
          {/* SUBTITLE */}
          <p
            className={`text-[18px] md:text-[20px] text-secondary font-bold text-center ${
              inView &&
              "animate__animated animate__fadeInLeft animation-duration"
            }`}
          >
            ¡Bienvenido!
          </p>

          {/* PARAGRAMS */}
          <div
            className={`flex flex-col gap-4 ${
              inView &&
              "animate__animated animate__fadeInLeft animation-duration"
            }`}
          >
            <p className="text-base  text-secondary  text-center">
              Esta guía te orientará, paso a paso, para que puedas ser parte del
              grupo elegido para este{" "}
              <span className="font-bold">beta TEST</span>
            </p>
            <p className="text-base  text-secondary  text-center">
              A continuación te invitamos a seguir el orden que presenta el
              siguiente índice
            </p>
          </div>
        </div>

        <div>
          {/* <div className="w-full flex flex-col gap-4 md:gap-10 py-6"> */}
          <WhatIs />
          <Characteristic />
          <BeforeStart />
          <Create />
          <Document />
          <Install />
          <InstallSkill />
          <SettingLenguaje />
          <Verify />
          <Use />
          <StepNext />
          <FormCode />
        </div>
      </section>
    </>
  );
};

export default ComoFunciona;
