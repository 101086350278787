import { useInView } from "react-intersection-observer";

import "animate.css";

import {
  IconChart,
  IconDiagrama,
  IconGlass,
  IconSend,
} from "../../images/icons";

import TitleSection from "../../components/TitleSection";
import CartFlip from "../../components/Cards/CartFlip";
import { useState } from "react";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import Carousel from "../../components/Carousel";

const Characteristic = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full h-full relative" ref={ref}>
      <div className="">
        <TitleSection
          title="Características de HyPUCP"
          color="primary"
          number="2"
          isOpen={isOpen}
          onClickOpen={(value) => setIsOpen(value)}
        />
        <ContentSectionLayout isOpen={isOpen}>
          <div className="flex gap-8 flex-wrap justify-center py-8">
            {/* CARD1 */}
            <Carousel
              data={[
                <CartFlip
                  textFront="Búsqueda rápida e inteligencia."
                  icon={<IconGlass className="w-[60px] " />}
                  animation={`${
                    inView &&
                    "animate__animated animate__fadeInLeft animation-duration"
                  }`}
                />,
                <CartFlip
                  textFront="Respuesta con IA."
                  icon={<IconChart className="w-[60px] " />}
                  animation={`${
                    inView &&
                    "animate__animated animate__fadeInDown animation-duration"
                  }`}
                />,
                <CartFlip
                  textFront="Organización de contenido."
                  icon={<IconDiagrama className="w-[60px] " />}
                  animation={`${
                    inView &&
                    "animate__animated animate__fadeInDown animation-duration"
                  }`}
                />,
                <CartFlip
                  textFront="Compartir contenido."
                  icon={<IconSend className="w-[60px] " />}
                  animation={`${
                    inView &&
                    "animate__animated animate__fadeInRight animation-duration"
                  }`}
                />,
              ]}
            />
          </div>
        </ContentSectionLayout>
      </div>
    </div>
  );
};

export default Characteristic;
