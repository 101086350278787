import { StaticImage } from "gatsby-plugin-image";

import { useInView } from "react-intersection-observer";

import "animate.css";

import { BsCursor } from "react-icons/bs";

import TitleSection from "../../components/TitleSection";
import Button from "../../components/Button";
import { useState } from "react";
import ContentSectionLayout from "../../components/ContentSectionLayout";

const FormCode = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TitleSection
        title="Luego de realizar las pruebas por favor rellena el siguiente cuestionario"
        color="primary"
        number="12"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div
          ref={ref}
          className={`py-6 ${
            inView &&
            "animate__animated animate__fadeInRight animation-duration"
          } flex  flex-col `}
        >
          <div className="w-full flex justify-center px-4 md:px-0">
            <StaticImage
              alt="login"
              className="w-auto"
              src="../../images/code.png"
            />
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://9r6jdc8eou6.typeform.com/to/z4Ke3qIM?typeform-source=docs.google.com"
            className="mx-auto mt-5 "
          >
            <Button
              rightIcon={<BsCursor className="w-6 h-6 lg:w-10 lg:h-10" />}
            >
              Gracias por tu participación
            </Button>
          </a>
        </div>
      </ContentSectionLayout>
    </>
  );
};

export default FormCode;
