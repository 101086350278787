import {
  Children,
  HtmlHTMLAttributes,
  ReactElement,
  cloneElement,
  useEffect,
  useState,
} from "react";
import { IconChevronLeft, IconChevronRight } from "../../images/icons";

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  data: ReactElement[];
}

const Carousel = ({ data, ...props }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(true);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) newIndex = 0;
    else if (newIndex >= data.length) newIndex = data.length - 1;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);

    // const handler = () => console.log();
    // window.document.addEventListener("resize", handler);
    // return () => window.document.removeEventListener("resize", handler);
  }, []);

  return (
    <>
      {!isMobile ? (
        Children.map(data, (child, index) =>
          cloneElement(child, { key: index })
        )
      ) : (
        <div
          className="w-full flex flex-col justify-center mx-auto overflow-hidden"
          {...props}
        >
          <div
            className="whitespace-nowrap transition-transform duration-300 w-full"
            style={{ transform: `translate(-${activeIndex * 100}%)` }}
          >
            {data.map((i, index) => (
              <div
                className="inline-flex justify-center w-full whitespace-normal"
                key={index}
              >
                {i}
              </div>
            ))}
          </div>

          <div className="flex justify-evenly items-center w-full">
            <button
              type="button"
              className="outline-none w-[40px] h-[40px] hover:bg-primary rounded-full text-gray-800 hover:text-white active:bg-primary"
              onClick={() => updateIndex(activeIndex - 1)}
            >
              <IconChevronLeft className="mx-auto" />
            </button>

            <div className="flex justify-center">
              {data.map((_, index) => (
                <button
                  type="button"
                  className={
                    "inline-block outline-none w-[40px] h-[40px] hover:bg-primary-300 hover:text-white " +
                    (index === activeIndex ? "bg-primary text-white" : "")
                  }
                  onClick={() => updateIndex(index)}
                  key={index}
                >
                  {index + 1}
                </button>
              ))}
            </div>

            <button
              type="button"
              className="outline-none w-[40px] h-[40px] hover:bg-primary rounded-full text-gray-800 hover:text-white active:bg-primary"
              onClick={() => updateIndex(activeIndex + 1)}
            >
              <IconChevronRight className="mx-auto" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Carousel;
