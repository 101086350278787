import { useInView } from "react-intersection-observer";

import "animate.css";

import Heading, { Color } from "../Heading";
import { IconChevronDown } from "../../images/icons";
import { classNames } from "../../utils/classNames";

interface Props {
  title: string;
  color: Color;
  number: string;
  isOpen: boolean;
  onClickOpen: (value: boolean) => void;
}

const TitleSection = ({ title, color, number, isOpen, onClickOpen }: Props) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <div
      className={`flex flex-row  mb-18 min-w-full ${
        inView && "animate__animated animate__fadeInLeft animation-duration"
      }`}
      ref={ref}
    >
      <div className="flex flex-row gap-4 items-center w-full  lg:px-0 bg-secondary py-6 relative">
        <div className=" min-w-[40px] min-h-[40px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] rounded-full bg-primary flex justify-center items-center ml-6">
          <p className="text-lg sm:text-2xl md:text-4xl text-white font-bold">
            {number}
          </p>
        </div>
        <Heading color={color} size="extra-big" variant="bold">
          {title}
        </Heading>

        <button
          type="button"
          onClick={() => onClickOpen(!isOpen)}
          className="absolute w-[40px] h-[40px] rounded-lg bg-secondary-600 top-5 right-5 flex items-center justify-center outline-none bg-opacity-60 text-white"
        >
          <IconChevronDown
            className={classNames([
              "icon-6 transition-transform",
              isOpen ? "rotate-180" : "rotate-0",
            ])}
          />
        </button>
      </div>
    </div>
  );
};

export default TitleSection;
