import TitleSection from "../../components/TitleSection";
import CartText from "../../components/Cards/CartText/Index";
import Text from "../../components/Text";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";
import Carousel from "../../components/Carousel";

const Create = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <TitleSection
        title="Crea tu cuenta en HyPUCP"
        color="primary"
        number="4"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="py-6 grid grid-cols-2 md:gap-8 md:max-w-[900px] mx-auto md:px-4 items-center">
          {/* <div className="flex flex-col px-4 sm:px-10 items-center justify-center gap-8  mx-auto mt-8"> */}
          {/* INGRESA */}
          <Carousel
            className="col-span-2"
            data={[
              <div className="flex flex-col md:flex-row gap-4 items-center justify-center col-span-2">
                <div className="min-w-[48px] min-h-[48px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] bg-secondary rounded-full flex justify-center items-center">
                  <p className="text-white font-bold text-2xl sm:text-3xl md:text-4xl">
                    1
                  </p>
                </div>

                <p className="text-secondary font-bold text-lg sm:text-xl  md:text-2xl text-center ">
                  Ingresa a la página{" "}
                  <a
                    href="https://app.hypucp.org/"
                    className="text-primary cursor-pointer"
                  >
                    https://app.hypucp.org/
                  </a>
                </p>
              </div>,
              <div className="col-span-1">
                <CartText
                  // animation="animate__animated animate__fadeInLeft animation-duration"
                  animation=""
                  number="2"
                  bg="bg-primary"
                  text={
                    <Text
                      size="2xl"
                      color="primary"
                      variant="bold"
                      className="text-center"
                    >
                      Selecciona la opción de{" "}
                      <span className="text-primary">"Registrase"</span> y a
                      continuación podrás colocar tus datos.
                    </Text>
                  }
                  img="login.png"
                />
              </div>,
              <div className="col-span-1">
                <CartText
                  // animation="animate__animated animate__fadeInLeft animation-duration"
                  animation=""
                  number="3"
                  bg="bg-secondary"
                  text={
                    <Text
                      size="2xl"
                      color="secondary"
                      variant="bold"
                      className="text-center"
                    >
                      Verás este formulario.
                    </Text>
                  }
                  img="logout-point.png"
                />
              </div>,
              <div className="col-span-2">
                <CartText
                  animation=""
                  // animation="animate__animated animate__fadeInLeft animation-duration"
                  number="4"
                  bg="bg-primary"
                  text={
                    <Text
                      size="2xl"
                      color="secondary"
                      variant="bold"
                      className="text-center"
                    >
                      Llena el formulario y selecciona donde dice{" "}
                      <span className="text-primary">"Crear cuenta"</span>
                    </Text>
                  }
                  img="logout.png"
                  sizeImage="max-w-[394px] "
                />
              </div>,
            ]}
          />

          {/* <div className="flex gap-4 items-center">
            <div className="min-w-[48px] min-h-[48px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] bg-secondary rounded-full flex justify-center items-center">
              <p className="text-white font-bold text-2xl sm:text-3xl md:text-4xl">
                1
              </p>
            </div>

            <p className="text-secondary font-bold text-lg sm:text-xl  md:text-2xl sm:text-center ">
              Ingresa a la página{" "}
              <a
                href="https://app.hypucp.org/"
                className="text-primary cursor-pointer"
              >
                https://app.hypucp.org/
              </a>
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
            <CartText
              animation="animate__animated animate__fadeInLeft animation-duration"
              number="2"
              bg="bg-primary"
              text={
                <Text
                  size="2xl"
                  color="primary"
                  variant="bold"
                  className="text-center"
                >
                  Selecciona la opción de{" "}
                  <span className="text-primary">"Registrase"</span> y a
                  continuación podrás colocar tus datos.
                </Text>
              }
              img="login.png"
            />

            <CartText
              animation="animate__animated animate__fadeInLeft animation-duration"
              number="3"
              bg="bg-secondary"
              text={
                <Text
                  size="2xl"
                  color="secondary"
                  variant="bold"
                  className="text-center"
                >
                  Verás este formulario.
                </Text>
              }
              img="logout-point.png"
            />
          </div>

          <CartText
            animation="animate__animated animate__fadeInLeft animation-duration"
            number="4"
            bg="bg-primary"
            text={
              <Text
                size="2xl"
                color="secondary"
                variant="bold"
                className="text-center"
              >
                Llena el formulario y selecciona donde dice{" "}
                <span className="text-primary">"Crear cuenta"</span>
              </Text>
            }
            img="logout.png"
            sizeImage="max-w-[394px] "
          /> */}
        </div>
      </ContentSectionLayout>
    </div>
  );
};
export default Create;
