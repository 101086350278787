import TitleSection from "../../components/TitleSection";
import CartNumber from "../../components/Cards/CartNumber";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";
import Carousel from "../../components/Carousel";

const Verify = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TitleSection
        title="Verifica que la habilidad (skill) está activada en versión desarrollo"
        color="primary"
        number="9"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="flex flex-col px-4 sm:px-10 items-center gap-8  mx-auto mt-8 max-w-[1200px]">
          <div className="flex flex-wrap justify-center gap-4 md:gap-8 items-center">
            <Carousel
              data={[
                <CartNumber
                  number="1"
                  img="step-verify-uno.png"
                  bg="bg-secondary"
                />,
                <CartNumber
                  number="2"
                  img="step-verify-dos.png"
                  bg="bg-primary"
                />,
                <CartNumber
                  number="3"
                  img="step-verify-tres.png"
                  bg="bg-secondary"
                />,
                <CartNumber
                  number="4"
                  img="step-verify-cuatro.png"
                  bg="bg-primary"
                />,
                <CartNumber
                  number="5"
                  img="step-verify-cinco.png"
                  bg="bg-secondary"
                />,
              ]}
            />
          </div>
        </div>
      </ContentSectionLayout>
    </>
  );
};

export default Verify;
