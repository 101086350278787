import { StaticImage } from "gatsby-plugin-image";
import TitleSection from "../../components/TitleSection";
import Text from "../../components/Text";
import { useInView } from "react-intersection-observer";
import "animate.css";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";

const Use = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TitleSection
        title="Haz uso de la IA para tus búsquedas"
        color="primary"
        number="10"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div
          className={`flex flex-col px-4 sm:px-10 items-center gap-8 max-w-[900px] mx-auto mt-8 ${
            inView && "animate__animated animate__fadeInLeft animation-duration"
          }`}
          ref={ref}
        >
          <div className="relative flex flex-col gap-4">
            <Text
              color="secondary"
              variant="bold"
              size="2xl"
              className="text-center"
            >
              Ahora que ya configuraste el idioma puedes seleccionar el circulo
              azul y dialogar con la habilidad.
            </Text>

            <div className="mt-2 w-full flex justify-center">
              <StaticImage
                alt="login"
                className="w-auto"
                src="../../images/use.png"
              />
            </div>
          </div>
        </div>
      </ContentSectionLayout>
    </>
  );
};

export default Use;
