import { ReactNode } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useInView } from 'react-intersection-observer'

import 'animate.css'

interface Props {
  number: string
  text: string | ReactNode
  img: string
  bg: string
  animation: string
  direcction?: string
  sizeImage?: string
}
const CartText = ({
  number,
  text,
  img,
  bg,
  animation,
  direcction = 'flex-col',
  sizeImage
}: Props) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0
  })
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const imageData = data.images.edges.find(
    (edge: any) => edge.node.relativePath === img
  )?.node.childImageSharp.gatsbyImageData
  return (
    <>
      <div
        className={`${
          inView && animation
        } flex flex-col  gap-4 items-center ${direcction} justify-between w-full`}
        ref={ref}
      >
        <div className="flex flex-col items-center gap-4 lg:gap-6 w-full justify-between">
          <div
            className={`min-w-[48px] min-h-[48px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] ${bg} rounded-full flex justify-center items-center`}
          >
            <p className="text-white font-bold text-2xl sm:text-3xl md:text-4xl">
              {number}
            </p>
          </div>

          {text}
        </div>
        <div className="mt-2 w-full flex justify-center">
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt={img}
              className={`w-auto shadow-lg ${sizeImage && sizeImage}`}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CartText
