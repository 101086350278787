import TitleSection from "../../components/TitleSection";
import CartText from "../../components/Cards/CartText/Index";
import Text from "../../components/Text";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import { useState } from "react";

const InstallSkill = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TitleSection
        title="Instala el skill de HyPUCP"
        color="primary"
        number="7"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="flex flex-col px-4 sm:px-10 items-center gap-8  mx-auto mt-8 max-w-[1200px]">
          {/* CORREO */}
          <CartText
            animation=""
            // animation="animate__animated animate__fadeInLeft animation-duration"
            number="1"
            bg="bg-secondary"
            text={
              <Text
                size="2xl"
                color="secondary"
                variant="bold"
                className="text-center"
              >
                Cuando instales la aplicación te llegara un correo electrónico
                donde encontraras un correo enviado desde{" "}
                <a className="text-primary">hypucp@pucp.edu.pe</a>
              </Text>
            }
            img="correo.png"
          />
          {/* ACTIVACION */}
          <CartText
            animation=""
            // animation="animate__animated animate__fadeInRight animation-duration"
            number="2"
            bg="bg-primary"
            text={
              <Text
                size="2xl"
                color="secondary"
                variant="bold"
                className="text-center"
              >
                Ahora ya tienes activado la habilidad (skill) de HyPUCP donde
                podrás hacer la búsqueda por VOZ sobre la información que
                subiste al sistema HyPUPC.
                <br />
                <span className="text-primary font-bold">Importante:</span> Leer
                la descripción de la habilidad
              </Text>
            }
            img="activacion.png"
          />
        </div>
      </ContentSectionLayout>
    </>
  );
};

export default InstallSkill;
