import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useInView } from 'react-intersection-observer'

import 'animate.css'

interface Props {
  img: string
  bg: string
  number: string
}
const CartNumber = ({ img, bg, number }: Props) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0
  })
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" } }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const imageData = data.images.edges.find(
    (edge: any) => edge.node.relativePath === img
  )?.node.childImageSharp.gatsbyImageData
  return (
    <>
      <div
        className={`flex flex-col items-center gap-4 lg:gap-6`}
        ref={ref}
      >
      {/* <div
        className={`flex flex-col items-center gap-4 lg:gap-6 ${
          inView && 'animate__animated animate__fadeInRight animation-duration'
        }`}
        ref={ref}
      > */}
        <div
          className={`min-w-[48px] min-h-[48px] sm:min-w-[56px] sm:min-h-[56px] md:min-w-[80px] md:min-h-[80px] ${bg} rounded-full flex justify-center items-center`}
        >
          <p className="text-white font-bold text-2xl sm:text-3xl md:text-4xl">
            {number}
          </p>
        </div>
        <div className="mt-2 w-full flex justify-center">
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt={img}
              className="w-auto shadow-lg"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CartNumber
