import TitleSection from "../../components/TitleSection";
import CartText from "../../components/Cards/CartText/Index";
import Text from "../../components/Text";
import { useState } from "react";
import ContentSectionLayout from "../../components/ContentSectionLayout";
import Carousel from "../../components/Carousel";

const Document = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <TitleSection
        title="Sube tus documentos a HyPUCP"
        color="primary"
        number="5"
        isOpen={isOpen}
        onClickOpen={(value) => setIsOpen(value)}
      />
      <ContentSectionLayout isOpen={isOpen}>
        <div className="flex flex-col px-4 sm:px-10 items-center gap-8 mx-auto mt-8 max-w-[1200px]">
          {/* INGRESA */}
          {/* UPLOAD DOCUMENTS */}
          {/* SELECTION UPLOAD */}
          <Carousel
            data={[
              <CartText
                animation=""
                // animation="animate__animated animate__fadeInLeft animation-duration"
                number="1"
                bg="bg-secondary"
                text={
                  <Text
                    size="2xl"
                    color="secondary"
                    variant="bold"
                    className="text-center"
                  >
                    Ingresa con tu usuario y contraseña
                  </Text>
                }
                img="login-upload.png"
                sizeImage="max-w-[500px]"
              />,
              <CartText
                // animation="animate__animated animate__fadeInRight animation-duration"
                animation=""
                number="2"
                bg="bg-primary"
                text={
                  <Text
                    size="2xl"
                    color="primary"
                    variant="bold"
                    className="text-center"
                  >
                    Selecciona subir tus documentos
                  </Text>
                }
                img="admin.png"
              />,
              <CartText
                // animation="animate__animated animate__fadeInLeft animation-duration"
                animation=""
                number="3"
                bg="bg-secondary"
                text={
                  <Text
                    size="2xl"
                    color="secondary"
                    variant="bold"
                    className="text-center"
                  >
                    Selecciona Upload
                  </Text>
                }
                img="admin-upload.png"
              />,
            ]}
          />
        </div>
      </ContentSectionLayout>
    </div>
  );
};

export default Document;
